import { useMemo } from 'react';
import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';
import { setContext } from '@apollo/client/link/context';
import {
  DRUPAL_CACHE_TAGS_HEADER_NAME,
  PUBLIC_RUNTIME_CONFIG,
  SERVER_RUNTIME_CONFIG,
} from '../config/constants';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

interface ApolloClientOptions {
  cacheTags?: Set<string>;
  headers?: Record<string, string>;
}

let apolloClient: ApolloClient<NormalizedCacheObject>;

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const httpLink = new HttpLink({
  uri: `${PUBLIC_RUNTIME_CONFIG.BACKEND_URL}/graphql`,
  credentials: 'same-origin',
});

const authLink = setContext((_, { headers }) => {
  if (
    SERVER_RUNTIME_CONFIG.HTTP_AUTH_USER &&
    SERVER_RUNTIME_CONFIG.HTTP_AUTH_USER !== '' &&
    SERVER_RUNTIME_CONFIG.HTTP_AUTH_PW &&
    SERVER_RUNTIME_CONFIG.HTTP_AUTH_PW !== ''
  ) {
    return {
      headers: {
        ...headers,
        Authorization: `Basic ${Buffer.from(
          `${SERVER_RUNTIME_CONFIG.HTTP_AUTH_USER}:${SERVER_RUNTIME_CONFIG.HTTP_AUTH_PW}`
        ).toString('base64')}`,
      },
    };
  }
});

function createApolloClient(options?: ApolloClientOptions) {
  // Cache Tags
  const cacheTagsAfterwareLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
      const context = operation.getContext();
      const headers = context.response.headers as Headers;

      if (
        headers &&
        headers.has(DRUPAL_CACHE_TAGS_HEADER_NAME) &&
        options?.cacheTags
      ) {
        const cacheTagsHeader = headers.get(DRUPAL_CACHE_TAGS_HEADER_NAME);

        // Add all tags to the set
        if (cacheTagsHeader) {
          cacheTagsHeader
            .split(' ')
            .forEach((cacheTag) => options?.cacheTags?.add(cacheTag));
        }
      }

      return response;
    });
  });

  const customHeadersLink = setContext((_, { headers }) => {
    if (options?.headers) {
      return {
        headers: {
          ...headers,
          ...options?.headers,
        },
      };
    }
  });

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: from([
      authLink,
      customHeadersLink,
      errorLink,
      cacheTagsAfterwareLink,
      httpLink,
    ]),
    cache: new InMemoryCache({}),
    defaultOptions: {
      query: {
        errorPolicy: 'all',
      },
    },
  });
}

export function initializeApollo(
  initialState = null,
  options?: ApolloClientOptions
) {
  const _apolloClient = apolloClient ?? createApolloClient(options);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
    const data = merge(existingCache, initialState, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function useApollo(pageProps: any) {
  const options: ApolloClientOptions = {};

  if (pageProps?.settings?.auth) {
    options.headers = {
      Authorization: pageProps.settings.auth,
    };
  }

  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state, options), [state]);
  return store;
}
