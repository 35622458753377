import { GA_TRACKING_ID } from '../config/constants';

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const GApageview = (url: string) => {
  (window as any).gtag('config', GA_TRACKING_ID, {
    page_path: url,
  });
};

interface GAEventArgs {
  action: string;
  category: string;
  label: string;
  value?: any;
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const GAevent = ({ action, category, label, value }: GAEventArgs) => {
  (window as any).gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};
