import { extendTheme } from '@chakra-ui/react';

const theme = {
  fonts: {
    body: 'Open Sans, sans-serif',
    heading: 'Rubik, serif',
  },
  fontSizes: {
    xxs: '0.65rem',
  },
  colors: {
    accent: '#F1F0E9',
    accentDark: '#E6E6DB',
    accentDarker: '#D3D3C5',
    primary: '#004f6c',
    primaryLight: '#d2f3ff',
    primaryDark: '#00364a',
    primaryDarker: '#002a39',
    secondary: '#ebc372',
    secondaryDark: '#e0a328',
    secondaryDarker: '#ac7b18',
    greenHighlight: '#0c946e',
  },
  sizes: {
    container: {
      main: '1184px',
      small: '760px',
    },
  },
  radii: {
    sm: '3px',
  },
  components: {
    Alert: {
      baseStyle: {
        container: {},
      },
      variants: {
        blue: {
          container: {
            bg: 'rgba(0, 79, 108, .15)',
          },
        },
        green: {
          container: {
            bg: 'rgba(12, 148, 110, .20)',
          },
        },
        red: {
          container: {
            bg: 'rgba(240, 62, 62, .3)',
          },
        },
        yellow: {
          container: {
            bg: 'rgba(235, 195, 114, .25)',
          },
        },
        gray: {
          container: {
            bg: 'gray.200',
          },
        },
      },
    },
    Button: {
      sizes: {
        sm: {
          py: 4,
          px: 6,
        },
        lg: {
          py: 5,
          px: 8,
        },
      },
      variants: {
        round: {
          borderRadius: 'full',
          textTransform: 'uppercase',
          color: 'white',
          bg: 'linear-gradient(to right, #CBB27C, #A37B3E)',
          transition: 'none',
          _hover: {
            bg: '#A37B3E',
          },
        },
        roundBlue: {
          borderRadius: 'full',
          textTransform: 'uppercase',
          color: 'white',
          bg: 'linear-gradient(to right, #004f6c, #00364a)',
          transition: 'none',
          _hover: {
            bg: '#00364a',
          },
          _loading: {
            _hover: {
              bg: '#00364a',
            },
          },
        },
        outlineBlue: {
          borderRadius: 'full',
          textTransform: 'uppercase',
          bg: 'none',
          border: '2px solid',
          borderColor: '#002946',
          color: '#002946',
          transition: 'none',
          _hover: {
            bg: '#002946',
            color: '#fff',
          },
        },
        outlineWhite: {
          borderRadius: 'full',
          textTransform: 'uppercase',
          bg: 'none',
          border: '2px solid',
          borderColor: '#fff',
          color: '#fff',
          transition: 'none',
          _hover: {
            bg: '#fff',
            color: 'primary',
          },
        },
      },
    },
    Heading: {
      variants: {
        textHeading: {
          fontSize: '2xl',
          color: 'primaryDark',
          fontFamily: 'body',
        },
        sectionHeading: {
          fontSize: '4xl',
          textAlign: 'center',
          color: 'primary',
          fontWeight: 500,
        },
        boxTitle: {
          pos: 'relative',
          fontSize: 'lg',
          textTransform: 'uppercase',
          color: 'primaryDark',
          pb: 2,
          _before: {
            content: '""',
            display: 'inline-block',
            h: '1px',
            bg: 'gray.100',
            width: '100%',
            pos: 'absolute',
            top: '100%',
          },
          span: {
            pos: 'relative',
            _before: {
              content: '""',
              display: 'inline-block',
              h: '1px',
              bg: 'primary',
              left: 0,
              right: 0,
              pos: 'absolute',
              bottom: '-11px',
            },
          },
        },
      },
    },
  },
  layerStyles: {
    link_basic_link: {
      display: 'inline-flex',
      alignItems: 'center',
      _hover: {
        textDecoration: 'underline',
      },
    },
    link_button_gold: {
      display: 'inline-flex',
      alignItems: 'center',
      py: {
        base: 1.5,
        md: 2,
      },
      px: {
        base: 3,
        md: 6,
      },
      borderRadius: 'full',
      textTransform: 'uppercase',
      color: 'white',
      bg: 'linear-gradient(to right, #CBB27C, #A37B3E)',
      transition: 'none',
      fontWeight: 'bold',
      _hover: {
        bg: '#A37B3E',
      },
    },
    link_button_gold_outline: {
      display: 'inline-flex',
      alignItems: 'center',
      py: {
        base: 1.5,
        md: 2,
      },
      px: {
        base: 3,
        md: 6,
      },
      borderRadius: 'full',
      textTransform: 'uppercase',
      color: '#A37B3E',
      border: '2px solid',
      borderColor: '#A37B3E',
      transition: 'none',
      fontWeight: 'bold',
      _hover: {
        bg: '#A37B3E',
        color: '#fff',
      },
    },
    link_button_blue_outline: {
      display: 'inline-flex',
      alignItems: 'center',
      py: {
        base: 1.5,
        md: 2,
      },
      px: {
        base: 3,
        md: 6,
      },
      borderRadius: 'full',
      textTransform: 'uppercase',
      color: '#00364a',
      border: '2px solid',
      borderColor: '#00364a',
      transition: 'none',
      fontWeight: 'bold',
      _hover: {
        bg: '#00364a',
        color: '#fff',
      },
    },
    link_button_blue: {
      display: 'inline-flex',
      alignItems: 'center',
      py: {
        base: 1.5,
        md: 2,
      },
      px: {
        base: 3,
        md: 6,
      },
      borderRadius: 'full',
      textTransform: 'uppercase',
      color: 'white',
      bg: 'linear-gradient(to right, #004f6c, #00364a)',
      transition: 'none',
      fontWeight: 'bold',
      _hover: {
        bg: '#00364a',
      },
    },
    shadowBox: {
      shadow: 'md',
      border: '1px solid',
      borderColor: 'primary',
      p: 4,
      borderRadius: 'sm',
    },
    divider_default: {
      mx: 'auto',
      display: 'block',
      w: '100px',
      height: '3px',
      bg: 'secondary',
    },
    divider_blue_horse: {
      pos: 'relative',
      mx: 'auto',
      display: 'block',
      w: '100px',
      height: '3px',
      _before: {
        content: '""',
        pos: 'absolute',
        left: 0,
        right: 'calc(50% + 20px)',
        bg: 'primary',
        height: '3px',
      },
      _after: {
        content: '""',
        pos: 'absolute',
        right: 0,
        left: 'calc(50% + 20px)',
        bg: 'primary',
        height: '3px',
      },
    },
    divider_white_horse: {
      pos: 'relative',
      mx: 'auto',
      display: 'block',
      w: '100px',
      height: '3px',
      _before: {
        content: '""',
        pos: 'absolute',
        left: 0,
        right: 'calc(50% + 20px)',
        bg: 'white',
        height: '3px',
      },
      _after: {
        content: '""',
        pos: 'absolute',
        right: 0,
        left: 'calc(50% + 20px)',
        bg: 'white',
        height: '3px',
      },
    },
  },
  textStyles: {},
  styles: {
    global: {
      '#klaro': {
        '--blue1': '#777',
      },
      '#nprogress': {
        '.bar': {
          bg: '#ebc372',
          height: '5px',
        },
        '.peg': {
          boxShadow: '0 0 10px #ebc372, 0 0 5px #ebc372',
        },
        '.spinner-icon': {
          borderTopColor: '#ebc372',
          borderLeftColor: '#ebc372',
        },
      },
      'h1,h2,h3,h4,h5,h6': {
        fontFamily: 'heading',
      },
      '.fs_xs': {
        fontSize: 'xs',
      },
      '.fs_md': {
        fontSize: 'md',
      },
      '.fs_lg': {
        fontSize: 'lg',
      },
      '.fs_xl': {
        fontSize: {
          base: 'md',
          md: 'xl',
        },
      },
      '.fs_2xl': {
        fontSize: {
          base: 'lg',
          md: '2xl',
        },
      },
      '.fs_3xl': {
        fontSize: {
          base: '2xl',
          md: '3xl',
        },
      },
      '.fs_4xl': {
        fontSize: {
          base: '3xl',
          md: '4xl',
        },
      },
      '.fs_5xl': {
        fontSize: {
          base: '3xl',
          md: '5xl',
        },
      },
      '.fs_6xl': {
        fontSize: {
          base: '4xl',
          md: '6xl',
        },
      },
      '.fs_7xl': {
        fontSize: {
          base: '5xl',
          md: '7xl',
        },
      },
      '.text-align-center': {
        textAlign: 'center',
      },
      '.text-align-left': {
        textAlign: 'left',
      },
      '.text-align-right': {
        textAlign: 'right',
      },
      '.text-align-justify': {
        textAlign: 'justify',
      },
    },
  },
};

export default extendTheme(theme);
