import type { AppProps } from 'next/app';
import { ApolloProvider } from '@apollo/client';
import { useApollo } from '../utils/apolloClient';
import { ChakraProvider } from '@chakra-ui/react';
import theme from '../theme';
import '../theme/fonts.css';
import 'nprogress/nprogress.css';
import useProgressBar from '../hooks/useProgressBar';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { GApageview } from '../utils/gtag';
import { ADSENSE_ID } from '../config/constants';
import Script from 'next/script';

function MyApp({ Component, pageProps }: AppProps) {
  const apolloClient = useApollo(pageProps);
  useProgressBar();

  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      GApageview(url);
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      {ADSENSE_ID && (
        <>
          <Script
            crossOrigin="anonymous"
            src={`https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-${ADSENSE_ID}`}
            async
            strategy="afterInteractive"
          />
        </>
      )}

      <Script
        src={`https://cdn.cookie-script.com/s/8ac0b74dbb66c782c03a5179fba8de01.js`}
        strategy="afterInteractive"
      />

      <ApolloProvider client={apolloClient}>
        <ChakraProvider resetCSS theme={theme}>
          <Component {...pageProps} />
        </ChakraProvider>
      </ApolloProvider>
    </>
  );
}

export default MyApp;
