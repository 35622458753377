import { useRouter } from 'next/router';
import { useEffect } from 'react';
import NProgress from 'nprogress';

export default function useProgressBar() {
  const router = useRouter();

  const start = () => {
    NProgress.start();
  };

  const done = () => {
    NProgress.done();
  };

  useEffect(() => {
    router.events.on('routeChangeStart', start);
    router.events.on('routeChangeComplete', done);
    router.events.on('routeChangeError', done);

    return () => {
      router.events.off('routeChangeStart', start);
      router.events.off('routeChangeComplete', done);
      router.events.off('routeChangeError', done);
    };
  }, []);
}
